import { Box, Button, Flex, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
// import { sectionStyles } from './styles'
import { SectionBlock } from 'starterUiComponents'

export const fragment = graphql`
  fragment advertiseBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_AdvertiseBlock {
    cssclass
    anchor
    ads {
      title
      subtitle
      bgcolor
      button {
        target
        title
        url
      }
      buttonVariant
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
              width: 289
              layout: FIXED
            )
          }
        }
      }
    }
  }
`
export const AdvertiseBlock = ({
  content,
  cssclass,
  ads,
  anchor,
  ...props
}) => {
  return (
    <SectionBlock anchor={anchor} cssclass={cssclass} my="60px" {...props}>
      <Wrap
        spacing={{ base: 8, lg: 12 }}
        justify="center"
        px={{ base: 4, sm: 0 }}
      >
        {ads.map((item, i) => {
          const { title, subtitle, image, button, buttonVariant, bgcolor } =
            item

          return (
            <WrapItem
              key={i}
              w={{ base: '100%', md: '80%', lg: '46%' }}
              borderRadius="20px"
              className=" bg-veryLightGrey"
              border="25px solid"
              borderColor="veryLightGrey"
            >
              <VStack
                spacing={5}
                align="center"
                borderRadius="20px"
                p={7}
                w="100%"
                h="100%"
                background={bgcolor.trim()}
                className={`border-2 border-white text-center`}
              >
                <GatsbyImage
                  image={image.localFile.childImageSharp.gatsbyImageData}
                  alt={title}
                />

                <Box
                  dangerouslySetInnerHTML={{ __html: title }}
                  fontFamily="heading"
                  fontWeight="light"
                  className=" text-l xl:text-l2"
                  lineHeight={1.2}
                  sx={{ strong: { textTransform: 'uppercase' } }}
                />
                <Box
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                  maxW={350}
                />
                <Flex justify="center">
                  <Button
                    as={Link}
                    to={button.url}
                    variant={buttonVariant}
                    w={['100%', 280]}
                  >
                    {button.title}
                  </Button>
                </Flex>
              </VStack>
            </WrapItem>
          )
        })}
      </Wrap>
    </SectionBlock>
  )
}
