import React from 'react'
import { chakra } from '@chakra-ui/react'
import { Container } from 'starterUiComponents'

export const SectionBlock = ({ anchor, cssclass, children, ...props }) => {
  return (
    <chakra.section
      id={anchor}
      className={`${cssclass || ''} contentBlock`}
      {...props}
    >
      <Container className="container">{children}</Container>
    </chakra.section>
  )
}
