import { Box, Center, Divider } from '@chakra-ui/react'
import React from 'react'
import {
  PostEntryContent,
  PostEntryInfo,
  PostEntryMedia,
  PostEntryMeta,
  PostEntryTitle,
  PrevNextPostNavigation,
  ReadMoreButton,
} from 'starterComponents'
import { Card } from 'starterUiComponents'

export const PostEntry = ({
  post,
  ctx,
  location,
  previousPagePath,
  nextPagePath,
  ...props
}) => {
  const noImgClass = !post.featuredImage ? 'no-img' : ''
  const media = post.featuredImage
    ? post.featuredImage.node.localFile.childImageSharp.gatsbyImageData
    : null

  return (
    <Box
      as="article"
      mb={14}
      className="entry"
      mt={location === 'single' && 10}
      {...props}
    >
      <PostEntryMedia
        location={location}
        post={post}
        className="entry-media"
        sx={{ '.gatsby-image-wrapper': { borderTopRadius: 'lg' } }}
      />

      <Card
        borderTopRadius={media ? 0 : 'lg'}
        className={`content ${noImgClass}`}
      >
        <PostEntryTitle
          location={location}
          post={post}
          textTransform="uppercase"
          fontSize={['2xl', '3xl']}
          mb={5}
          className="entry-title"
        />
        <PostEntryInfo className="entry-info" post={post} />

        <PostEntryContent location={location} post={post} />

        <Center height={20}>
          <Divider />
        </Center>

        <div className="entry-footer">
          <PostEntryMeta
            className="entry-meta"
            post={post}
            key={post.databaseId}
          />
          <ReadMoreButton location={location} post={post} />
        </div>
        {location === 'single' && (
          <>
            <PrevNextPostNavigation
              previousPagePath={previousPagePath}
              nextPagePath={nextPagePath}
            />
          </>
        )}
      </Card>
    </Box>
  )
}
