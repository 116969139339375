import { mode } from '@chakra-ui/theme-tools'
import { mergeWith as merge } from '@chakra-ui/utils'
import Input from './Input'

const parts = ['field', 'icon']

function baseStyleField(props) {
  return {
    ...Input.baseStyle.field,
    appearance: 'none',
    paddingBottom: '1px',
    lineHeight: 'normal',
    '> option, > optgroup': {
      bg: mode('white', 'gray.700')(props),
    },
  }
}

const baseStyleIcon = {
  width: '1.5rem',
  height: '100%',
  right: '0.5rem',
  position: 'relative',
  color: 'currentColor',
  fontSize: '1.25rem',
  _disabled: {
    opacity: 0.5,
  },
}

const baseStyle = (props) => ({
  field: baseStyleField(props),
  icon: baseStyleIcon,
})

const sizes = merge({}, Input.sizes, {
  xs: {
    icon: {
      right: '0.25rem',
    },
  },
})

const Select = {
  parts,
  baseStyle,
  sizes,
  variants: Input.variants,
  defaultProps: Input.defaultProps,
}

export default Select
