const layerStyles = {
  overlay: {
    position: 'relative',
    zIndex: 0,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      bg: 'currentColor',
      opacity: 0.03,
      zIndex: -1,
    },
  },
  info: {
    px: 4,
    py: 1,
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: 'sm',
    borderRadius: 0,
    color: 'white !important',
    display: 'inline-block',
    lineHeight: 'shorter',
  },
  input: {
    background: 'white',
    borderRadius: 0,
    borderColor: 'border',
    fontSize: 'sm',
    h: '55px',
    transition: '0.6s',
    '&:focus, &:hover': {
      color: 'black',
    },
  },
  card: {
    py: { base: '30px', md: '60px' },
    px: ['40px', '80px'],
    bg: 'white',
    borderRadius: '20px',
  },
}

export default layerStyles
