import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'
import { useAuth } from 'contexts/AuthContext'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { FaShoppingCart as CartIcon } from 'react-icons/fa'
import { Menu, UserNotUser } from 'starterComponents'
import { useSiteData } from 'starterUtils/hooks'

export const SlideSidebar = (props) => {
  const { logo } = useStaticQuery(
      graphql`
      {
        logo: file(relativePath: { eq: "logo.png" }) {
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
              width: 118
            )
          }
        }
      }
    `
  )
  const { currentUser } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const menuName = currentUser ? 'members mobile' : 'mobile'
  const data = useSiteData()
  const { salesNumber } = data.wp.options.info

  return (
      <Box {...props}>
        <div className="flex items-center">
          <Link to="/">
            <GatsbyImage
                image={logo.childImageSharp.gatsbyImageData}
                alt="logo"
                loading="eager"
            />
          </Link>
          <a className="text-sm font-light" href={`tel:${salesNumber}`}>
            {salesNumber}
          </a>
        </div>
        <div className="flex items-center">
          <a
              href={ "https://atsgbookstore.com/new-releases/" }
              target="_blank"
              rel="noreferrer"
              className="hover:text-yellow"
          >
            <Icon
                as={CartIcon}
                aria-label="cart"
                boxSize="20px"
                className="mx-3 cursor-pointer xl:mx-5"
                _hover={{ color: 'yellow' }}
                sx={{ 'a:hover': { color: 'yellow' } }}
            />
          </a>
          <IconButton
              aria-label="open menu"
              variant="unstyled"
              icon={<HamburgerIcon boxSize={6} color="darkGrey" />}
              onClick={onOpen}
              size="sm"
              boxSize={8}
              cursor="pointer"
              width="auto"
          />
        </div>
        <Drawer p={0} isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />

          <DrawerContent
              bg="grey"
              minW={{ base: '100%', md: '420px' }}
              color="white"
              shadow="2xl"
              // p={4}
          >
            <Box h="82px" className="flex items-center bg-darkGrey pl-7">
              <UserNotUser iconColor="darkGrey" onClose={onClose} />
            </Box>
            <DrawerBody>
              <DrawerCloseButton />
              <Menu
                  menuName={menuName}
                  orientation="V"
                  mt={4}
                  onClose={onClose}
                  // sx={{
                  //   '.sub-menu  a': { fontWeight: 'light' },
                  // }}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
  )
}
