import React from 'react'
import { Box } from '@chakra-ui/react'
import ParsedContent from '../../utils/ParsedContent'
import { gutenbergStyles } from '../../styles/gutenbergStyles'

export const PostEntryContent = ({ post, location, ...props }) => {
  const content = location === 'single' ? post.content : post.excerpt
  const attributes = location === 'single' ? { id: 'content' } : {}

  return (
    <Box {...attributes} {...props} sx={{ ...gutenbergStyles }}>
      <Box className="entry-content">
        <ParsedContent content={content} />
      </Box>
    </Box>
  )
}
