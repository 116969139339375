import React from 'react'
import { chakra } from '@chakra-ui/react'
import { Newsletter, FooterContent, FatFooter } from 'starterComponents'

export const Footer = () => (
  <chakra.footer>
    <Newsletter />
    <FatFooter className="text-white bg-grey py-7" />
    <FooterContent className="py-3 text-white bg-darkGrey" />
  </chakra.footer>
)
