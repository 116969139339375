import React from 'react'
import { Box, Flex, Heading, VStack } from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ContentButton, SectionBlock } from 'starterUiComponents'

export const fragment = graphql`
  fragment ctaBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_CtaBlock {
    title
    subtitle
    content
    cssclass
    anchor
    button {
      target
      title
      url
    }
    link {
      target
      title
      url
    }
  }
`
export const CtaBlock = ({
  title,
  subtitle,
  link,
  button,
  content,
  cssclass,
  anchor,
  index,
  ...props
}) => {
  cssclass = cssclass?.trim()
  anchor = anchor?.trim()
  const bgUrl =
    cssclass === 'brown'
      ? '/brownbg.png'
      : cssclass === 'blue'
      ? '/backpattern.jpg'
      : ''

  const buttonVariant = cssclass === 'blue' ? 'lightBlue' : 'outline'

  const { certifiedImg, shield } = useStaticQuery(
    graphql`
      {
        certifiedImg: file(relativePath: { eq: "certified.jpeg" }) {
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        shield: file(relativePath: { eq: "shield.png" }) {
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  return (
    <SectionBlock
      key={index}
      anchor={anchor}
      cssclass={cssclass}
      {...props}
      bg={anchor === 'bgGrey' && 'light'}
    >
      <VStack
        spacing="30px"
        align="center"
        borderRadius="20px"
        py={{ base: 10, sm: 20 }}
        className={` ${
          cssclass !== 'transparent' && 'text-white'
        }  px-4 text-center `}
        backgroundImage={`url(${bgUrl})`}
        backgroundSize="cover"
      >
        {cssclass.includes('transparent') && (
          <Flex justify="center">
            {cssclass.includes('certified') ? (
              <GatsbyImage
                image={certifiedImg.childImageSharp.gatsbyImageData}
                alt="icon"
              />
            ) : (
              <GatsbyImage
                image={shield.childImageSharp.gatsbyImageData}
                alt="icon"
              />
            )}
          </Flex>
        )}
        {title && (
          <Heading
            dangerouslySetInnerHTML={{ __html: title }}
            textTransform="uppercase"
            fontSize={{ base: 'l', sm: 'xl', md: 'xxl' }}
            lineHeight={1.1}
            fontWeight={cssclass === 'blue' ? 'bold' : 'light'}
            sx={{
              '.brown &': { strong: { color: 'yellow' } },
              '.transparent &': {
                color: 'text',
                fontSize: { base: 'l', sm: 'xl' },
              },
            }}
          />
        )}
        {subtitle && (
          <Box
            dangerouslySetInnerHTML={{ __html: subtitle }}
            maxW="700px"
            sx={{
              '.transparent &': {
                fontWeight: 'bold',
                textTransform: 'capitalize',
                fontFamily: 'heading',
                fontSize: 'l',
                span: { color: 'lightBlue' },
              },
            }}
          />
        )}
        {content && (
          <Box
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
            maxW="1000px"
            sx={{
              color: 'text',
              '.transparent  &': { p: { fontSize: 'sm' } },
            }}
          />
        )}
        {button && (
          <ContentButton
            button={button}
            variant={buttonVariant}
            sx={{
              '.transparent &': {
                a: {
                  borderColor: 'lightBlue',
                  color: 'text',
                  _hover: { bg: 'lightBlue', color: 'white' },
                },
              },
            }}
          />
        )}
        {link && (
          <a
            href={link.url}
            className="inline-block underline uppercase text-md"
          >
            {link.title}
          </a>
        )}
      </VStack>
    </SectionBlock>
  )
}
