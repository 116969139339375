import React from 'react'
import { Flex, Wrap } from '@chakra-ui/react'
import { SectionBlock } from 'starterUiComponents'

// const Title = ({ title, pdflinks, openModalWithEmbeddedPDF }) => {
//   if (pdflinks.length === 1 && pdflinks[0].file.title === title) {
//     return (
//       <a
//         className="font-bold uppercase text-darkBlue text-md font-heading cursor-pointer"
//         target="_blank"
//         onClick={(e) => openModalWithEmbeddedPDF(pdflinks[0], title)}
//       >
//         <h1 className="mt-5">{title}</h1>
//       </a>
//     )
//   } else {
//     return <h1 className="mt-5">{title}</h1>
//   }
// }

export const PublicationCorrectionsBlock = ({
  title,
  pdflinks,
  openModalWithEmbeddedPDF,
  ...props
}) => {
  const showTitle =
    pdflinks.length !== 1 ||
    (pdflinks.length === 1 && pdflinks[0].file.title !== title)
  const showIndependantChild =
    pdflinks.length === 1 && pdflinks[0].file.title === title // For ZF6HP
  return (
    <SectionBlock>
      <Flex w="100%" justify="center" flexDirection="column">
        <Wrap spacing={5} justify={{ base: 'center', xl: 'flex-start' }}>
          <Flex justify="center" h="100%" w="100%" direction="column">
            {/* <Title
              title={title}
              pdflinks={pdflinks}
              openModalWithEmbeddedPDF={openModalWithEmbeddedPDF}
            /> */}
            {showTitle && <h1 className="mt-5">{title}</h1>}
            {!showIndependantChild && (
              <ul className="ml-3">
                {pdflinks?.map(
                  (pdflink, index) =>
                    pdflink?.file && (
                      <li key={index} className="list-none mt-2">
                        <a
                          className="font-bold uppercase text-darkBlue text-md font-heading cursor-pointer"
                          target="_blank"
                          onClick={(e) =>
                            openModalWithEmbeddedPDF(pdflink, title)
                          }
                        >
                          {pdflink?.file?.title}
                        </a>
                      </li>
                    )
                )}
              </ul>
            )}
            {showIndependantChild && (
              <ul className="ml-3 mt-5">
                <li className="list-none mt-2">
                  <a
                    className="font-bold uppercase text-darkBlue text-md font-heading cursor-pointer"
                    target="_blank"
                    onClick={(e) =>
                      openModalWithEmbeddedPDF(pdflinks[0], title)
                    }
                  >
                    {pdflinks[0]?.file?.title}
                  </a>
                </li>
              </ul>
            )}
          </Flex>
        </Wrap>
      </Flex>
    </SectionBlock>
  )
}
