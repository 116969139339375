import { SearchIcon } from '@chakra-ui/icons'
import {
  Button,
  chakra,
  Flex,
  FormControl,
  Select,
  Stack,
} from '@chakra-ui/react'
import { navigate } from 'gatsby'
import range from 'lodash/range'
import React, { useEffect, useState, useCallback } from 'react'
import ApiFetch from '../../utils/api'

export const ManualSelect = (props) => {
  const [selectors, setSelectors] = useState({
    years: [],
    makes: [],
    models: [],
    engines: [],
    transmissions: [],
  })

  const [selected, setSelected] = useState({
    year: '',
    make: '',
    model: '',
    engine: '',
  })

  const [fetching, setFetching] = useState(false)
  const { year, make, model, engine } = selected
  const { years, makes, models, engines } = selectors

  const getYearsSelectors = useCallback((oldestYear) => {
    const currentYear = new Date().getFullYear()
    return range(oldestYear, currentYear + 1, 1).reverse()
  }, [])

  const fetchSelectors = React.useCallback(
    async (year, make, model, engine) => {
      let url = `/transmissions/selectors?year=${year}`
      make && (url += `&make=${make}`)
      model && (url += `&model=${model}`)
      engine && (url += `&engine=${engine}`)
      try {
        setFetching(true)
        const result = await ApiFetch({
          method: 'GET',
          path: url,
        })
        setSelectors((selectors) => ({
          ...selectors,
          makes: result?.makes ?? [],
          models: result?.models ?? [],
          engines: result?.engines ?? [],
          transmissions: result?.transmissions ?? [],
        }))
      } catch (error) {
        console.log('error: ', error)
      } finally {
        setFetching(false)
      }
    },
    []
  )

  useEffect(() => {
    if (year) {
      fetchSelectors(year, make, model, engine)
    }
  }, [fetchSelectors, year, make, model, engine])

  const fetchYear = React.useCallback(async () => {
    ApiFetch({
      method: 'GET',
      path: '/transmissions/lowest-year',
    })
      .then((res) => {
        setSelectors((selectors) => ({
          ...selectors,
          years: getYearsSelectors(res.lowestYear),
        }))
      })
      .catch((err) => {
        // console.log('err: ', err)
      })
  }, [getYearsSelectors])

  useEffect(() => {
    fetchYear()
  }, [fetchYear])

  const onSelectChange = (selector, e) => {
    const fieldName = e.target.name

    if (fieldName === 'make') {
      setSelected({
        ...selected,
        model: '',
        engine: '',
        [fieldName]: e.target.value,
      })
    } else if (fieldName === 'year') {
      setSelected({
        make: '',
        model: '',
        engine: '',
        [fieldName]: e.target.value,
      })
    } else if (fieldName === 'model') {
      setSelected({
        ...selected,
        engine: '',
        [fieldName]: e.target.value,
      })
    } else {
      setSelected({
        ...selected,
        [fieldName]: e.target.value,
      })
    }
  }

  const onGoToTransmissions = () => {
    const transmissionSelectors = {
      selected: {
        year,
        make,
        model,
        engine,
      },
      selectors,
    }
    localStorage.setItem(
      'transmissionSelectors',
      JSON.stringify(transmissionSelectors)
    )
    navigate('/transmission-lookup')
  }

  return (
    <chakra.form w="100%" {...props}>
      <Stack
        sx={{
          select: {
            my: 2,
          },
        }}
      >
        <FormControl id="year">
          <Select
            placeholder="Select Year"
            name="year"
            layerStyle="input"
            onChange={(e) => onSelectChange('year', e)}
            value={year}
          >
            {years?.map((year) => {
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              )
            })}
          </Select>
        </FormControl>

        <FormControl id="make">
          <Select
            placeholder={fetching ? 'loading' : 'Select Make'}
            name="make"
            layerStyle="input"
            value={make}
            onChange={(e) => onSelectChange('make', e)}
          >
            {makes?.map((make) => {
              return (
                <option key={make} value={make}>
                  {make}
                </option>
              )
            })}
          </Select>
        </FormControl>

        <FormControl id="model">
          <Select
            placeholder={fetching ? 'loading' : 'Select Model'}
            name="model"
            layerStyle="input"
            onChange={(e) => onSelectChange('model', e)}
            value={model}
          >
            {models?.map((model) => {
              return (
                <option key={model} value={model}>
                  {model}
                </option>
              )
            })}
          </Select>
        </FormControl>

        <FormControl id="engine">
          <Select
            placeholder={fetching ? 'loading' : 'Select Engine Size'}
            name="engine"
            layerStyle="input"
            onChange={(e) => onSelectChange('engine', e)}
            value={engine}
          >
            {engines.map((engine) => {
              return (
                <option key={engine} value={engine}>
                  {engine}
                </option>
              )
            })}
          </Select>
        </FormControl>

        <Flex justify="flex-end">
          <Button
            variant="outline"
            borderColor="orange"
            mt={5}
            color="grey"
            _hover={{ bg: 'orange' }}
            w={{ sm: 145 }}
            value="search"
            leftIcon={<SearchIcon />}
            onClick={onGoToTransmissions}
          >
            Search
          </Button>
        </Flex>
      </Stack>
    </chakra.form>
  )
}
