import { Link } from 'gatsby'
import React from 'react'

export const TaxonomyItem = ({ taxName, item, ...props }) => (
  <Link
    className="mx-1 mb-3 sm:mb-0 hover:underline"
    // mr={3}
    // mb={[3, 0]}
    to={`${item.uri}`}
    aria-label={`visit ${taxName} ${item.name} page`}
  >
    {item.name}
  </Link>
)
