import React from 'react'
import { chakra } from '@chakra-ui/react'
import { Collapse } from 'starterUiComponents'
import { MenuLink, MenuItem } from 'starterComponents'

export const SubMenu = ({ menuItem, orientation, onClose }) => {
  const WithCollapse = ({ orientation, children, menuItem }) =>
    orientation === 'V' ? (
      <Collapse menuItem={menuItem}>{children}</Collapse>
    ) : (
      children
    )

  const subMenuStyles = {
    position: 'absolute',
    zIndex: 10,
    top: '60px',
    left: 0,
    bg: 'blueSubMenu',
    color: 'white',
    shadow: 'lg',
    display: 'none',
    width: 'auto',
    whiteSpace: 'nowrap',

    px: 1,
    py: 4,
    '>li': {
      py: 1,
      a: {
        fontWeight: 'normal',
        textTransform: 'capitalize',
        fontSize: 'md',
        '&:hover': {
          color: 'orange',
        },
      },
    },
  }

  return (
    <chakra.li
      className="has-subMenu menu-item"
      position="relative"
      key={menuItem.id}
    >
      <WithCollapse orientation={orientation} menuItem={menuItem}>
        {orientation === 'H' && <MenuLink menuItem={menuItem} />}

        <chakra.ul
          className="menuItemGroup sub-menu"
          sx={orientation === 'H' && { ...subMenuStyles }}
        >
          {menuItem.children.map((item) => (
            <MenuItem key={item.id} menuItem={item} onClose={onClose} />
          ))}
        </chakra.ul>
      </WithCollapse>
    </chakra.li>
  )
}
