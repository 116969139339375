//palette
const yellow = '#F8C772',
  orange = '#e6a52c',
  light = '#f2f2f2',
  grey = '#545558',
  darkGrey = '#3e3d40',
  lightBlue = '#50A1EC',
  darkBlue = '#222D53',
  darkBlueGrey = 'rgba(56, 71, 94, 1.00)',
  blue = '#466288',
  lightGrey = '#acadaf',
  red = '#bd1e29',
  white = '#fff',
  veryLightGrey = '#ebebeb',
  blueGrey = '#2d2a56',
  blueSubMenu = '#384D73',
  brown = '#403f42'

module.exports = {
  bg: light,
  text: grey,
  border: '#B6B7BA',
  veryLightGrey,
  darkBlueGrey,
  blueSubMenu,
  blueGrey,
  light,
  brown,
  grey,
  darkGrey,
  lightBlue,
  darkBlue,
  blue,
  yellow,
  orange,
  red,
  lightGrey,
  white,
  placeholder: lightGrey,
}
