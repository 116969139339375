const Link = {
  baseStyle: {
    _hover: {
      textDecoration: 'none',
      color: 'primary',
      transition: 'all .2s eas-out',
    },
  },
}

export default Link
