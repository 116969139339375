// import Menu from './Menu'
import Button from './Button'
import Card from './Card'
import Checkbox from './Checkbox'
import CenterContainer from './Container'
import Input from './Input'
import Link from './Link'
import Radio from './Radio'
import Select from './Select'
import Textarea from './Textarea'

const Styles = {
  Card,
  CenterContainer,
  // Menu,
  Button,
  Link,
  Input,
  Checkbox,
  Textarea,
  Radio,
  Select,
}

export default Styles
