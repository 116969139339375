import { Box } from '@chakra-ui/react'
import React from 'react'
// import { Breadcrumbs, Footer, Header } from 'starterComponents'
import { Breadcrumbs } from "../components/Breadcrumbs"
import { Footer } from "../components/footer/Footer"
import { Header } from "../components/header/Header"
import Seo from '../components/seo'
import { useLocation } from '@reach/router';


const Layout = (props) => {
    const {
        children,
        uri,
        pageContext: { seo },
    } = props

    let include_seo = true
    let include_breadcrumbs = true
    let include_header = true
    let include_footer = true

    if (!uri || uri === '/' || uri === '/membership-program') {
        include_breadcrumbs = false
    }

    if ((new URLSearchParams(useLocation().search)).get("iniframe") === "true") {
        include_seo = include_breadcrumbs = include_header = include_footer = false
    }

    return (
        <Box
            sx={{
                '&.fullWidth': {
                    '.mainContainer': {
                        maxWidth: `100%`,
                        px: 0,
                    },
                },
            }}
        >
            {include_seo && (
                <Seo {...seo} />
            )}
            {include_header && (
                <Header />
            )}

            <Box
                as="main"
                pb={ include_footer ? 16 : 0 }
                sx={{
                    '.fullWidth &': {
                        py: 0,
                        // mt: '-32px',
                    },
                    '.sign-in-page &': {
                        bg: 'light',
                    },
                }}
            >
                {include_breadcrumbs && (
                    <Breadcrumbs pageTitle={seo?.title} {...props} pt={4} />
                )}

                {children}
            </Box>
            {include_footer && (
                <Footer />
            )}
        </Box>
    )
}

export default Layout
