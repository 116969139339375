import React from 'react'

import { Table, Thead, Tbody, Tr } from '@chakra-ui/react'

export const ResultsTable = ({ headers, children, ...props }) => {
  return (
    <Table variant="unstyled" size="sm" sx={{ 'th,td': { p: 3 } }} {...props}>
      <Thead className="px-8 py-6 uppercase bg-light" sx={{ th: { py: 5 } }}>
        <Tr
          className="flex"
          sx={{ th: { fontSize: { base: '12px', sm: 'md' } } }}
        >
          {headers}
        </Tr>
      </Thead>
      <Tbody>{children}</Tbody>
    </Table>
  )
}
