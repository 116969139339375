import {Select} from '@chakra-ui/react'
import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'


export const BulletinsCatSelect = ({setCategory, selectedCategory, setDisplayCategoryName, selectRef}) => {
    const data = useStaticQuery(graphql`
    query {
        allMysqlMake(
            sort: { fields: [make], order: ASC }
        ) {
            nodes {
                make
                id
                id_
            }
        }
    }
  `)
    const cats = data.allMysqlMake.nodes
    return (
        <Select
            placeholder="Please Select"
            layerStyle="input"
            onChange={(e) => {
                const targetCategory = e.target.value ? JSON.parse(e.target.value) : e.target.value
                setCategory(targetCategory)
                setDisplayCategoryName(targetCategory.make)
            }}
            ref={selectRef}
        >
            {cats.map((item) => (
                <option key={item.id} value={JSON.stringify(item)} id={item.id} name={item.id}>
                    {item.make}
                </option>
            ))}
        </Select>
    )
}
