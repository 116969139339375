import React from 'react'
import { Box, chakra } from '@chakra-ui/react'

export const FormSection = ({
  title,
  bgColor,
  children,
  plSmall,
  ...props
}) => {
  return (
    <Box mb={7} {...props}>
      {title && (
        <chakra.h3
          bg={bgColor}
          className={` text-md font-bold text-white py-5 px-7 capitalize`}
        >
          {title}
        </chakra.h3>
      )}
      <Box
        className={`py-6 pl-5 pr-5 bg-light md:pr-12 ${
          plSmall ? 'md:pl-12' : 'md:pl-52'
        }`}
      >
        {children}
      </Box>
    </Box>
  )
}
