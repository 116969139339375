import { useEffect, useState } from 'react'
import getFirebase from '../lib/firebase' // import our getFirebase function

export default function useFirebase() {
  const [instance, setInstance] = useState(null)

  useEffect(() => {
    const config = {
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
    }
    setInstance(getFirebase(config))
  }, [])

  return instance
}
