import React from 'react'

import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export const SiteBranding = ({ title, ...rest }) => {
  const { logo } = useStaticQuery(
    graphql`
      {
        logo: file(relativePath: { eq: "logo.png" }) {
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  return (
    <Link to="/" rel="home">
      <GatsbyImage
        loading="eager"
        image={logo.childImageSharp.gatsbyImageData}
        alt="logo"
      />
    </Link>
  )
}
