import { ApolloProvider } from '@apollo/client'
import React from 'react'
import config from '../../config'
import { themeOptions } from '../../context'
import { createClient } from '../apollo/client'
import defaultOptions from '../utils/defaultOptions'
import Layout from './layout'

const App = (props) => {
  const mergedOptions = {
    ...defaultOptions,
    ...config,
  }
  const client = createClient(process.env.GATSBY_WP_URL)
  return (
    <ApolloProvider client={client}>
      <themeOptions.Provider value={mergedOptions}>
        <Layout {...props} />
      </themeOptions.Provider>
    </ApolloProvider>
  )
}

export default App
