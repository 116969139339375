import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'gatsby'
import { Box, Alert, Button, Center, Input, chakra, FormErrorMessage } from '@chakra-ui/react'
import { Container, FormElement } from 'starterComponents'
import ApiFetch from '../../utils/api'

export const RecoverEmailBySubnum = () => {

    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)
    const { handleSubmit, register, getValues, reset, errors } = useForm()
    const [emails, setEmails] = useState(null)
    const [subnumSearched, setSubnumSearched] = useState(null)

    const onSubmit = async ({ subnum }, e) => {
        setSubmitting(true)
        try {
            const result = await ApiFetch({
                method: 'GET',
                path: `/auth/recover-email/${subnum}`
            })

            if (result.success) {
                setEmails({
                    activatedEmails: result?.activatedEmails ?? [],
                    notActivatedEmails: result?.notActivatedEmails ?? [],
                })
                setSubmitting(false)
                setSubnumSearched(subnum)
                reset()
            } else if (result.error) {
                console.error("result.error.message: ", result.error.message)
                setError("An unexpected error happened!")
                setSubmitting(false)
            }
        } catch (e) {
            console.error("error: ", e)
            setError("An unexpected error happened!")
            setSubmitting(false)
        }
    }
    return (
        <Container size="md" style={{ minHeight: "50vh" }}>
            <chakra.h2 className="mb-10 text-center uppercase text-l2">
                Recover your email address
            </chakra.h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <Input
                        type="text"
                        name="subnum"
                        className="mb-2 w-3/5"
                        placeholder="Your subscriber number"
                        ref={register({ required: true })}
                        onChange={() => emails && setEmails(null)}
                    />
                    {errors?.subnum?.type === "required" && <div className="text-red mb-2">
                        Subnum is required
                    </div>}
                    <Link
                        to="/sign-in"
                        className="font-bold uppercase text-darkBlue text-md font-heading"
                    >
                        Go back to login
                    </Link>
                </div>
                <Center>
                    <Button
                        variant="lightBlue"
                        disabled={submitting}
                        mt={4}
                        type="submit"
                    >
                        {`Retriev${submitting ? 'ing' : 'e'} your  email`}
                    </Button>
                </Center>
            </form>
            <Box className="mt-4">
                {emails?.activatedEmails?.length > 0 && (
                    <div className="mb-4">
                        <h4>{emails?.activatedEmails?.length > 1 ? 'These are ' : 'This is '}the active email{emails?.activatedEmails?.length > 1 ? 's' : ''} attached to the subscriber number {subnumSearched}:</h4>
                        <p>{emails?.activatedEmails.join(', ')}</p>
                    </div>
                )}
                {emails?.activatedEmails?.length == 0 && (
                    <h4 className="mb-4">Couldn't find any active email for {subnumSearched} subscriber number.</h4>
                )}
                {emails?.notActivatedEmails?.length > 0 && (
                    <div className="mb-4">
                        <h4>{emails?.notActivatedEmails?.length > 1 ? 'These are ' : 'This is '}the disabled email{emails?.notActivatedEmails?.length > 1 ? 's' : ''} attached to this subscriber number {subnumSearched}:</h4>
                        <p>{emails?.notActivatedEmails.join(', ')}</p>
                    </div>
                )}
                {error && (
                    <Alert color="white" status="error" className="mt-4">
                        {error}
                    </Alert>
                )}
            </Box>
        </Container >
    )
}
