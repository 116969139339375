import React, { useState, useContext, useEffect } from 'react'
// import firebase from "gatsby-plugin-firebase"
import useFirebase from '../hooks/useFirebase'

import ApiFetch from '../utils/api'

const AuthContext = React.createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const firebase = useFirebase()

  useEffect(() => {
    if (!firebase) return
  }, [firebase])

  const [currentUser, setCurrentUser] = useState(undefined)
  const [loading, setLoading] = useState(true)

  const signup = (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password)
  const login = (email, password) => {
    // setCurrentUser(undefined);
    return firebase.auth().signInWithEmailAndPassword(email, password)
  }
  const logout = () => firebase.auth().signOut()
  const resetPassword = (email) => firebase.auth().sendPasswordResetEmail(email)
  const updateEmail = async (email, password) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.firebase_.auth.EmailAuthProvider.credential(
      user.email,
      password
    );
    return user.reauthenticateWithCredential(credential).then((res) => {
      // User re-authenticated.
      return user.updateEmail(email)
    }).catch((error) => {
      console.error("error: ", error)
      return error
    });
  }
  const updatePassword = (password) => currentUser.updatePassword(password)

  useEffect(() => {
    setLoading(true)
    if (!firebase) return
    return firebase.auth().onAuthStateChanged(async (user) => {
      setLoading(true)
      if (user) {
        try {
          setCurrentUser(user)
          const idToken = await firebase.auth().currentUser.getIdToken(true)
          const result = await ApiFetch({
            method: 'POST',
            path: `/auth/loginCheck`,
            body: {
              idToken,
            },
          })
          result.shopData.idToken = idToken
          if (result) {
            user.shopData = result.shopData
          }
        } catch (error) {
          console.log('error: ', error)
          setCurrentUser(false)
        }
      }
      setLoading(false)
      setCurrentUser(user)
    })
  }, [firebase])

  const updateDisplayName = (name) => {
    if (currentUser) {
      currentUser
        .updateProfile({
          displayName: name,
        })
        .then(function () {
          // console.log(`user successfully updated with displayName ${name}`)
        })
        .catch(function (error) {
          console.log('error', error)
        })
    }
  }

  const updateUser = (user) => {
    setCurrentUser(user)
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        signup,
        login,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        updateDisplayName,
        updateUser,
        firebase
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
