import React from 'react'

import { Menu, SubHeader, SlideSidebar } from 'starterComponents'

import { Container } from 'starterUiComponents'
import { Box, Icon, HStack, chakra } from '@chakra-ui/react'
import { FaShoppingCart as CartIcon, FaSearch } from 'react-icons/fa'

import { useAuth } from 'contexts/AuthContext'
import Headroom from 'react-headroom'

export const Header = () => {
  const { currentUser } = useAuth()

  return (
      <>
        <Box display={{ base: 'none', lg: 'block' }}>
          <SubHeader py={4} className="subHeader" />
          <Box
              as="header"
              background="linear-gradient(90deg, rgba(70,97,137,1) 66%, rgba(56, 71, 94, 1.00) 66%)"
              className="text-white header"
          >
            <Container className="flex items-center justify-between">
              <Menu
                  className="menuLeft"
                  menuName={currentUser ? 'members main' : 'main'}
                  orientation="H"
              />

              <HStack className="justify-between hidden pl-5 bg-darkBlueGrey lg:block">
                <Menu menuName="right" orientation="H" className="menuRight" />
                <chakra.a
                    href={ "https://atsgbookstore.com/search.php?search_query=" }
                    target="_blank"
                    rel="noopener"
                    _hover={{ color: 'yellow' }}
                >
                  <Icon
                      as={FaSearch}
                      aria-label="search"
                      boxSize="20px"
                      className="mx-3 cursor-pointer xl:mx-5"
                      _hover={{ color: 'yellow' }}
                      sx={{ 'a:hover': { color: 'yellow' } }}
                  />
                </chakra.a>
                <chakra.a
                    href={ "https://atsgbookstore.com/cart.php" }
                    target="_blank"
                    rel="noopener"
                    _hover={{ color: 'yellow' }}
                >
                  <Icon
                      as={CartIcon}
                      aria-label="cart"
                      boxSize="20px"
                      className="mx-3 cursor-pointer xl:mx-5"
                      _hover={{ color: 'yellow' }}
                      sx={{ 'a:hover': { color: 'yellow' } }}
                  />
                </chakra.a>
              </HStack>
            </Container>
          </Box>
        </Box>
        <Box as={Headroom}>
          <SlideSidebar className="flex items-center justify-between px-4 py-3 bg-white shadow-md lg:hidden" />
        </Box>
      </>
  )
}
