import React from 'react'
import { AuthProvider } from './src/contexts/AuthContext'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import './src/styles/tailwind.css'
import './src/styles/recaptcha.css'

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_PUBLIC}>
      <AuthProvider>{element}</AuthProvider>
    </GoogleReCaptchaProvider>
  )
}
