import { Box, Stack, VStack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { ContentButton, SectionBlock, TitleSubtitle } from 'starterUiComponents'

export const fragment = graphql`
  fragment ctaTwoColsBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_CtaTwoColsBlock {
    title
    subtitle
    content
    cssclass
    anchor
    image {
      altText
      localFile {
        publicURL
      }
    }
    button {
      target
      title
      url
    }
    link {
      target
      title
      url
    }
  }
`

export const CtaTwoColsBlock = ({
  title,
  subtitle,
  link,
  button,
  content,
  cssclass,
  anchor,
  image,
  ...props
}) => {
  return (
    <SectionBlock anchor={anchor} cssclass={cssclass} {...props}>
      <Stack
        direction={{ base: 'columns', md: 'row' }}
        spacing={0}
        flexWrap="wrap"
      >
        <VStack
          spacing={7}
          justify="center"
          w={{ base: '100%', md: '50%' }}
          py={{ base: '80px', lg: '60px' }}
          px={{ base: '20px', lg: '40px' }}
          backgroundImage={`url(${image?.localFile.publicURL})`}
          backgroundSize="cover"
          alt={image.altText}
          backgroundRepeat="no-repeat"
          borderRadius={{ base: '20px 20px 0 0', md: '20px 0 0 20px' }}
          color="white"
          sx={{
            h2: {
              fontWeight: '300',
              letterSpacing: 0,
              lineHeight: ['inherit', '56px'],
              span: { color: 'yellow', fontWeight: 'bold', m: 0 },
            },
            p: { fontWeight: 'bold', m: 0, pt: ['10px', '25px'] },
          }}
        >
          <TitleSubtitle title={title} subtitle={subtitle} />
          {button && <ContentButton button={button} variant="outlineWhite" />}
        </VStack>
        <Box
          w={{ base: '100%', md: '50%' }}
          bg="light"
          borderRadius={{ base: '0 0 20px 20px', md: '0 20px 20px 0' }}
          py={['40px', '50px']}
          pr={['40px', '40px']}
          pl={['60px', '70px']}
        >
          <Box
            dangerouslySetInnerHTML={{ __html: content }}
            sx={{ p: { my: 2, fontSize: 'sm' } }}
          />
          {link && (
            <ContentButton
              button={link}
              variant="unstyled"
              className="h-4 mt-4 underline text-orange"
              sx={{ a: { textAlign: 'left', _hover: { color: 'blue' } } }}
            />
          )}
        </Box>
      </Stack>
    </SectionBlock>
  )
}
