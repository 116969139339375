import React from 'react'
import ContentParser from './ContentParser'
// import { cf7ParserFunction } from 'gatsby-plugin-wpcf7'
// import { lightboxParserFunction } from 'gatsby-plugin-wordpress-lightbox'

const ParsedContent = ({ content }) => {
  return <ContentParser content={content} />
}

export default ParsedContent
