const config = {
  //wordPressUrl: 'https://atsgco.wpengine.com',
  wordPressUrl: process.env.GATSBY_WP_URL,

  siteUrl: 'https://example.com',
  //pathPrefix: '/demo',

  // uploadsPath: 'wp-content/uploads',
  // paginationPrefix: 'page',

  // disqus: process.env.GATSBY_DISQUS_NAME,
  menuName: 'main',
  addWordPressComments: false,
  // gaTrackingId: 0,
  // googleTagManagerId: 0,
  // addSiteMap: false,
  // siteMapOptions: {},

  skipTitle: 'all',
  layoutWidth: {
    page: 'lg',
    post: 'lg',
    archive: 'lg',
  },
  sidebarWidgets: ['Categories', 'RecentPosts', 'Tags'],
  // slideMenuWidgets: [],
  archiveSidebar: 'right',

  // twitterSummaryCardImage: 'Gatsby_Monogram.png',
  // gaTrackingId: 0,
  // googleTagManagerId: 0,
  // addSiteMap: false,
  // siteMapOptions: {},
  seoWithYoast: true,
}

export { config, config as default }
