import { chakra } from '@chakra-ui/react'
import React from 'react'
import { MenuLink, SubMenu } from 'starterComponents'

export const MenuItem = ({ menuItem, onClose }) => {
  if (menuItem.children.length) {
    return <SubMenu menuItem={menuItem} />
  } else {
    return (
      <chakra.li
        className={`menu-item ${menuItem.cssClasses}`}
        key={menuItem.id}
        sx={{
          '&:hover': {
            // bg: 'darkBlue',
          },
        }}
      >
        <MenuLink role="menu" menuItem={menuItem} onClose={onClose} />
      </chakra.li>
    )
  }
}
