import { Box, Heading, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { ManualSelect } from 'starterComponents'
import { SectionBlock } from 'starterUiComponents'
import { sectionStyles } from './styles'

export const fragment = graphql`
  fragment searchBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_SearchBlock {
    title
    cssclass
    anchor
    subtitle
  }
`
export const SearchBlock = ({
  title,
  subtitle,
  cssclass,
  anchor,
  ...props
}) => {
  return (
    <SectionBlock
      anchor={anchor}
      cssclass={cssclass}
      sx={{ ...sectionStyles, bg: 'light' }}
      {...props}
    >
      <Wrap justify="center" align="flex-start">
        <WrapItem w={{ base: '100%', lg: '45%' }}>
          <Box textAlign={{ sm: 'center', lg: 'right' }}>
            <Heading
              as="h3"
              textAlign={{ sm: 'center', lg: 'right' }}
              fontSize={['2.5rem', 'xxl']}
              lineHeight={1.1}
              dangerouslySetInnerHTML={{ __html: title }}
              className="mb-6 uppercase"
              sx={{ span: { display: 'block', color: 'orange' } }}
            />
            <Box maxW={450}>
              <Text
                dangerouslySetInnerHTML={{ __html: subtitle }}
                textAlign={{ sm: 'center', lg: 'right' }}
              />
            </Box>
          </Box>
        </WrapItem>
        <WrapItem w={{ base: '100%', lg: '45%' }}>
          <ManualSelect />
        </WrapItem>
      </Wrap>
    </SectionBlock>
  )
}
