import { Box, Button, HStack, Input } from '@chakra-ui/react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import React, { useState } from 'react'
import { Container } from 'starterUiComponents'

export const Newsletter = (props) => {
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState()
  const handleSubmit = (e) => {
    e.preventDefault()
    addToMailchimp(email).then((data) => {
      return data.result === 'success'
        ? setMsg(data.msg)
        : setMsg('This email has already subscribed, try with another one')
    })
  }
  const handleChange = (e) => {
    setEmail(e.target.value)
  }
  return (
    <Box className="py-5 text-white bg-darkBlue" {...props}>
      <Container className="items-center justify-between block xl:flex">
        <Box w={{ base: 'auto', xl: '850px' }}>
          <div className="mb-3 text-sm xl:mb-0 ">
            <strong className="block uppercase font-heading text-l text-yellow">
              JOIN OUR MONTHLY NEWSLETTER
            </strong>
            Get tips, advice, and special offers to help grow your business.
          </div>
        </Box>

        {msg ? (
          msg
        ) : (
          <form onSubmit={handleSubmit} className="w-full">
            <HStack spacing={6}>
              <Input
                placeholder="Email address"
                name="email"
                type="email"
                color="darkBlue"
                w="100%"
                required
                value={email}
                onChange={handleChange}
                aria-label="Add your Email address to subsribe"
              />
              <Button
                type="submit"
                _hover={{ bg: 'white', color: 'darkBlue' }}
                className="ml-5 border-2 border-white "
              >
                sign-up
              </Button>
            </HStack>
          </form>
        )}
      </Container>
    </Box>
  )
}
