import { chakra, Text, VStack } from '@chakra-ui/react'
import React from 'react'

export const TitleSubtitle = ({
  title,
  subtitle,
  // titleTag = 'h2',
  ...props
}) => {
  return (
    <VStack textAlign="center" {...props}>
      <chakra.h2
        className="px-4 font-bold text-center uppercase title md:px-0 font-heading text-l sm:text-xl"
        sx={{ span: { fontWeight: 'light' } }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {subtitle && (
        <Text
          as="p"
          className="text-center subtitle"
          maxW={800}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </VStack>
  )
}
