import React from 'react'
import URIParser from 'urijs'
import slashes from 'remove-trailing-slash'
import { createLocalLink } from 'starterUtils'
import { Link } from 'gatsby'
import { useThemeOptions } from '../../../context'

export const MenuLink = ({ menuItem, onClose }) => {
  const { wordPressUrl } = useThemeOptions()

  let url = menuItem.url
  const parsedUrl = new URIParser(url)
  if (parsedUrl.is('absolute') && !url.includes(wordPressUrl)) {
    const targetRelAttrs =
      menuItem.target === '_blank'
        ? { target: '_blank', rel: 'noopener noreferrer' }
        : {}
    return (
      <a href={menuItem.url} {...targetRelAttrs} onClick={() => onClose && onClose()}>
        {menuItem.label}
      </a>
    )
  } else {
    return menuItem.url !== '#' ? (
      menuItem.url === wordPressUrl ? (
        <Link to="/" dangerouslySetInnerHTML={{ __html: menuItem.label }}
          onClick={() => onClose && onClose()}
        />
      ) : (
        <Link
          to={createLocalLink(menuItem.url, slashes(wordPressUrl))}
          dangerouslySetInnerHTML={{ __html: menuItem.label }}
          onClick={() => onClose && onClose()}
        />
      )
    ) : (
      menuItem.label
    )
  }
}
