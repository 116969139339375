import React from 'react'
import { Box, Button, Center, HStack, Icon } from '@chakra-ui/react'
import { Link, navigate } from 'gatsby'
import { FaSignOutAlt, FaUserAlt, FaUserCircle } from 'react-icons/fa'
import { useAuth } from 'contexts/AuthContext'

export const UserNotUser = ({ iconColor, button, onClose }) => {
  const { currentUser, logout } = useAuth()
  async function handleLogout() {
    try {
      await logout()
      navigate('/sign-in')
    } catch {
      // setError('Failed to log out')
    }
  }
  return (
    <>
      {currentUser ? (
        <Box>
          <Box className="text-sm textCenter">
            Welcome {currentUser?.shopData?.contact}!
          </Box>
          <HStack>
            <Icon
              as={FaUserCircle}
              aria-label="my-acount"
              boxSize={5}
              color="lightBlue"
              className=""
            />

            <Link
              to="/account"
              className="relative text-base uppercase font-heading"
              onClick={() => onClose && onClose()}
            >
              {' '}
              MY ACCOUNT
            </Link>
            <Icon
              as={FaSignOutAlt}
              aria-label="sign-out"
              boxSize={5}
              color="lightBlue"
              className=""
              onClick={handleLogout}
            />

            <Link
              to="/logout"
              className="relative text-base uppercase font-heading"
              onClick={(e) => {
                e.preventDefault()
                onClose && onClose()
                handleLogout()
              }}
            >
              LOGOUT
            </Link>
          </HStack>
        </Box>
      ) : (
        <HStack spacing={10}>
          <HStack>
            <Center className="rounded-full bg-lightBlue" h="20px" w="20px">
              <Icon
                as={FaUserAlt}
                aria-label="user"
                color={iconColor}
                className="rounded-full "
              />
            </Center>
            <Link
              to="/sign-in"
              className="relative text-base uppercase font-heading"
              onClick={() => onClose && onClose()}
            >
              Member Sign In
            </Link>
          </HStack>
          {button && (
            <Button
              as={Link}
              to="/membership-signup-form"
              aria-label="sign up"
              variant="red"
            >
              Become a Member!
            </Button>
          )}
        </HStack>
      )}
    </>
  )
}
