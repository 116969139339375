import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { SectionBlock } from 'starterUiComponents'

export const fragment = graphql`
  fragment imageBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_ImageBlock {
    cssclass
    anchor
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            height: 363
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`
export const ImageBlock = ({ image, cssclass, anchor, ...props }) => {
  return image && (
    <SectionBlock anchor={anchor} my="40px" cssclass={cssclass} {...props}>
      <GatsbyImage
        alt={image.altText}
        image={getImage(image.localFile)}
        className="rounded-twenty"
      />
    </SectionBlock>
  )
}
