import { createBreakpoints } from '@chakra-ui/theme-tools'
import themeConfig from '../../../../themeConfig'

const rem = (number) => number / 16 + 'rem'

const {
  colors,
  screens,
  spacing,
  borderRadius,
  borderWidth,
  boxShadow,
  widths,
  letterSpacing,
  lineHeight,
  fontWeight,
  fontFamily,
} = themeConfig

const foundations = {
  breakpoints: createBreakpoints(screens),
  colors,
  space: spacing,
  border: borderWidth,
  radii: borderRadius,
  shadows: boxShadow,
  sizes: widths,
  fontSizes: {
    sm: rem(14),
    base: rem(16),
    md: rem(18),
    l: rem(24),
    xl: rem(48),
    xxl: rem(60),
  },
  fontWeights: fontWeight,
  fonts: fontFamily,
  letterSpacings: letterSpacing,
  lineHeights: lineHeight,
  typography: {
    fontSizes: {
      sm: rem(14),
      base: rem(16),
      md: rem(18),
      l: rem(24),
      xl: rem(48),
      xxl: rem(60),
    },
    fontWeights: fontWeight,
    lineHeights: lineHeight,
  },
}

export default foundations
