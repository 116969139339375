import React from 'react'

import { HStack, Box, Link as ChakraLink } from '@chakra-ui/react'
import { Container } from 'starterUiComponents'
import { SiteBranding, Menu, UserNotUser } from 'starterComponents'
import { useStaticQuery, graphql } from 'gatsby'
import { useAuth } from 'contexts/AuthContext'

export const SubHeader = (props) => {
  const { currentUser } = useAuth()

  const data = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          title
          url
        }
        options {
          info {
            salesNumber
          }
        }
      }
    }
  `)
  const { title } = data.wp.generalSettings
  const { salesNumber } = data.wp.options.info
  return (
    <Box {...props}>
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <HStack spacing={{ base: 5, xl: 12 }}>
          <SiteBranding title={title} />
          <ChakraLink fontWeight="light" href={`tel:${salesNumber}`}>
            {salesNumber}
          </ChakraLink>
          {currentUser && (
            <Menu className="menuTop" menuName="member top" orientation="H" />
          )}
        </HStack>
        <UserNotUser iconColor="white" button />
      </Container>
    </Box>
  )
}
