import React from 'react'
import { Box, FormControl, FormLabel, Stack } from '@chakra-ui/react'

export const FormElement = ({
  id,
  label,
  type,
  children,
  required,
  ...props
}) => {
  return (
    <FormControl id={id} isRequired={required} mb={5} {...props}>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        align={type === 'input' && { base: 'flex-start', lg: 'center' }}
        justify="space-between"
      >
        <FormLabel
          textAlign={{ base: 'left', lg: 'right' }}
          fontSize="sm"
          fontWeight="bold"
          w={{ base: '100%', lg: '350px' }}
          sx={{ '.chakra-form__required-indicator': { color: 'red', ml: 0 } }}
        >
          {label}
        </FormLabel>
        <Box w={{ base: '100%', lg: '560px' }}>{children}</Box>
      </Stack>
    </FormControl>
  )
}
