import { Box } from '@chakra-ui/react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import './PostEntryMedia.css'

const WithLink = ({ post, location, children }) =>
  location === 'single' ? (
    children
  ) : (
    <Link to={`${post.uri}`} aria-label="View the entire post">
      {children}
    </Link>
  )

export const PostEntryMedia = ({ post, location, ...props }) => {
  const image = getImage(post.featuredImage?.node.localFile)

  return (
    <>
      {image && (
        <WithLink location={location} post={post}>
          <Box className="-mb-2 entry-media" {...props}>
            <GatsbyImage
              alt="entry-media"
              image={image}
              imgStyle={{ width: '100%' }}
              objectFit={{ objectFit: 'fill', objectPosition: 'center' }}
              className={'totalWidth'}
            />
          </Box>
        </WithLink>
      )}
    </>
  )
}
