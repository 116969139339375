
const SERVER_API_URL = process.env.GATSBY_SERVER_API_URL;
const SERVER_API_KEY = process.env.GATSBY_SERVER_API_KEY;

const ApiFetch = async ({ path, method = 'GET', body = null, userToken = null }) => {
    try {
        const params = {
            method,
            headers: {
                "Content-Type": "application/json",
                Accept: 'application/json',
                Authorization: `Bearer ${SERVER_API_KEY}`
            },
        }
        if (userToken) {
            params.headers.token = userToken;
        }
        if (body) params.body = JSON.stringify(body)
        const result = await fetch(`${SERVER_API_URL}${path}`, params)
            .then((response) => response.json())

        return result;

    } catch (error) {
        console.error("error: ", error)
        throw new Error(`Error on ApiFetch ${error.message}`)
    }
}

export default ApiFetch;