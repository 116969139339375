import { Box } from '@chakra-ui/react'
import React from 'react'

export const PostEntryInfo = ({ post, ...props }) => {
  return (
    <Box mb={5} {...props}>
      <strong>Posted on: </strong> {post.date}
    </Box>
  )
}
