const parts = ['field', 'addon']

const baseStyle = {
  field: {
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transition: 'all 0.2s',
    bg: 'white',
    px: 4,
    border: '1px solid',
    borderColor: 'border',
    fontSize: 'sm',
  },
}

const size = {
  md: {
    fontSize: 'md',
    px: 4,
    h: 55,
    borderRadius: 0,
  },
}

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
}

function variantOutline(props) {
  return {
    field: {
      border: '1px solid',

      bg: 'white',
      px: 4,
      borderColor: 'border',
      fontSize: 'sm',
      height: 55,
      _hover: {
        borderColor: 'grey',
      },
    },
  }
}

const variants = {
  outline: variantOutline,
}

const defaultProps = {
  size: 'md',
  variant: 'outline',
}

const Input = {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps,
}
export default Input
