import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { TaxonomyItem } from 'starterComponents'

export const Taxonomies = ({ post, taxName, singularName, ...props }) => {
  const taxonomies = post[taxName].nodes

  return (
    taxonomies.length > 0 && (
      <Flex wrap="wrap" align="center" my={5}>
        <Box className="mr-3 text-base font-bold uppercase font-heading">
          {taxonomies.length > 1 ? `${taxName} : ` : `${singularName} : `}
        </Box>
        <Flex wrap="wrap" {...props}>
          {taxonomies.map((cat, i) => (
            <>
              <TaxonomyItem key={cat.id} taxName={cat.name} item={cat} />

              {i + 1 < taxonomies.length && '/'}
            </>
          ))}
        </Flex>
      </Flex>
    )
  )
}
