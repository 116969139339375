import { Box, Link as ChakraLink, Wrap } from '@chakra-ui/react'
import { useAuth } from 'contexts/AuthContext'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { FooterColumn, FooterMenu } from 'starterComponents'
import { Container } from 'starterUiComponents'

export const FatFooter = (props) => {
  const { currentUser } = useAuth()
  const data = useStaticQuery(graphql`
    query MyQuery {
      wp {
        options {
          info {
            address
            salesNumber
            supportNumber
          }
        }
      }
    }
  `)

  const { address, salesNumber } = data.wp.options.info
  return (
    <Box {...props}>
      <Container>
        <Wrap
          spacing={{ base: 10, md: 20, lg: 10, xl: 20 }}
          justify={{ base: 'flex-start', lg: 'space-between' }}
          sx={{ '>ul': { display: { base: 'block', md: 'flex' } } }}
          className="text-sm "
        >
          <FooterColumn title="ATSG">
            <Box>
              <div dangerouslySetInnerHTML={{ __html: address }} />
              Sales:
              <ChakraLink href={`tel:${salesNumber}`}>{salesNumber}</ChakraLink>
            </Box>
          </FooterColumn>
          <FooterColumn title="Resources">
            <FooterMenu menuName="resources" />
          </FooterColumn>
          <FooterColumn title="Education">
            <FooterMenu menuName="education" />
          </FooterColumn>
          <FooterColumn title="Company">
            <FooterMenu menuName="company" />
          </FooterColumn>
          {currentUser && (
            <FooterColumn title="Members Only">
              <FooterMenu menuName="members only" />
            </FooterColumn>
          )}
        </Wrap>
      </Container>
    </Box>
  )
}
