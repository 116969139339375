import React from 'react'
import { Flex } from '@chakra-ui/react'
import { Link } from 'gatsby'

const LinkButton = (props) => (
  <Link
    className="text-sm tracking-wider underline uppercase hover:underline font-heading"
    {...props}
  />
)
const renderPreviousLink = (previousPagePath) => {
  let previousLink = null
  if (!previousPagePath) {
    return <span />
  } else {
    previousLink = `${previousPagePath}`
  }

  return (
    <LinkButton to={previousLink} aria-label="visit previous post">
      <span>Previous</span>
    </LinkButton>
  )
}

const renderNextLink = (nextPagePath) => {
  if (nextPagePath) {
    return (
      <LinkButton to={nextPagePath} aria-label="visit next post">
        <span>Next</span>
      </LinkButton>
    )
  } else {
    return <span />
  }
}

export const PrevNextPostNavigation = ({ previousPagePath, nextPagePath }) => {
  return (
    <Flex as="nav" justify="space-between" mt={16}>
      {renderPreviousLink(previousPagePath)}
      {renderNextLink(nextPagePath)}
    </Flex>
  )
}
