import { Alert, AlertIcon, Button, chakra, Input } from '@chakra-ui/react'
import { useAuth } from 'contexts/AuthContext'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Container } from 'starterUiComponents'

export const ForgotPassword = () => {
  const { handleSubmit, register } = useForm()
  const { resetPassword } = useAuth()
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')

  const onSubmit = async (data, e) => {
    const { email } = data
    e.preventDefault()

    try {
      setMessage('')
      setError('')
      await resetPassword(email)
      setMessage('Check your inbox for further instructions')
    } catch {
      setError('Failed to reset password')
    }
  }

  return (
    <Container size="sm">
      <chakra.h2 className="mb-10 text-center uppercase text-l2">
        Password Reset
      </chakra.h2>
      {error && (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      )}
      {message && (
        <Alert status="success">
          <AlertIcon />
          {message}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          type="email"
          placeholder="Your email"
          ref={register}
          required
          className="mb-6"
        />
        <div className="flex">
          <Button type="submit" mr={5}>
            Reset Password
          </Button>
          <Button as={Link} variant="red" to="/sign-in">
            Sign In
          </Button>
        </div>
      </form>
    </Container>
  )
}
