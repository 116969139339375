import { Center, Wrap, WrapItem } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { SectionBlock } from 'starterUiComponents'

export const fragment = graphql`
  fragment warrantyTermsBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_WarrantyTermsBlock {
    title

    cssclass
    anchor
    terms {
      valueOne
      valueTwo
    }
  }
`

export const WarrantyTermsBlock = ({
  title,
  terms,
  cssclass,
  anchor,
  ...props
}) => {
  return (
    <SectionBlock
      anchor={anchor}
      cssclass={cssclass}
      className="my-12"
      {...props}
    >
      <h3 dangerouslySetInnerHTML={{ __html: title }} className="mb-5 text-l" />
      <Wrap
        spacing={5}
        justifyContent={{ base: 'center', xl: 'space-between' }}
      >
        {terms?.map((term, i) => {
          const { valueTwo, valueOne } = term

          return (
            <WrapItem key={i}>
              <Center
                w="224px"
                h="166px"
                className="flex-col bg-darkBlue rounded-twenty"
              >
                <h5 className="text-yellow text-l">{valueOne}</h5>
                <div className="font-medium text-white font-md">-or-</div>
                <h5 className="text-yellow text-l">{valueTwo}</h5>
              </Center>
            </WrapItem>
          )
        })}
      </Wrap>
    </SectionBlock>
  )
}
