import { Box, Button, chakra, Stack, VStack } from '@chakra-ui/react'
import { graphql, Link } from 'gatsby'
import React from 'react'
import { Login } from 'starterComponents'
import { SectionBlock } from 'starterUiComponents'

export const fragment = graphql`
  fragment signInBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_SignInBlock {
    title
    subtitle
    content
    cssclass
    anchor
  }
`

export const SignInBlock = ({
  title,
  subtitle,
  content,
  cssclass,
  anchor,
  ...props
}) => {
  return (
    <SectionBlock
      anchor={anchor}
      bg="light"
      paddingTop="60px"
      paddingBottom="60px"
      cssclass={cssclass}
      mb={9}
      {...props}
    >
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={12}
        position="relative"
      >
        <Box
          className={`justify-between w-full  column lg:w-1/2 md:py-14 p-5 4 md:px-20 leading-tight bg-white rounded-twenty`}
          height={{ base: 'auto', md: '518px' }}
        >
          <h2 className="mb-8 font-bold text-center uppercase text-lightBlue text-l2">
            My Account
          </h2>
          <Login />
        </Box>
        <VStack
          className={`justify-between w-full  column lg:w-1/2 md:py-14 p-5 4 md:px-20 leading-tight bg-white rounded-twenty`}
          height={{ base: 'auto', md: '518px' }}
        >
          <div>
            <chakra.h2
              className="mb-3 font-light text-center uppercase text-l2"
              sx={{ span: { color: 'lightBlue', fontWeight: 'bold' } }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <h3
              className="mb-8 text-center text-l"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
            <Box
              sx={{ li: { fontSize: 'sm', mb: 2 } }}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <Button
            as={Link}
            to="/membership-signup-form"
            variant="outlineLightBlue"
          >
            Sign-up today
          </Button>
        </VStack>
      </Stack>
    </SectionBlock>
  )
}
