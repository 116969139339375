import {
  Box,
  Button,
  Center,
  chakra,
  Link as ChakraLink,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { SectionBlock, TitleSubtitle } from 'starterUiComponents'
import { sectionStyles } from './styles'

export const fragment = graphql`
  fragment postsBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_PostsBlock {
    title
    cssclass
    anchor
    subtitle
  }
`

const LAST_POSTS_QUERY = graphql`
  query {
    allWpPost(limit: 3, sort: { order: DESC, fields: date }) {
      nodes {
        id
        title
        uri
        excerpt
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 660
                  height: 500
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
export const PostsBlock = ({ title, subtitle, cssclass, anchor, ...props }) => {
  const data = useStaticQuery(LAST_POSTS_QUERY)
  const { nodes: posts } = data.allWpPost
  return (
    <SectionBlock
      anchor={anchor}
      cssclass={cssclass}
      sx={{ ...sectionStyles }}
      {...props}
    >
      <TitleSubtitle title={title} subtitle={subtitle} mb={10} />
      <Wrap spacing={5} justify="center">
        {posts?.map((post) => {
          const { title, id, uri, featuredImage, excerpt } = post
          const postImage = getImage(featuredImage?.node.localFile)
          return (
            <WrapItem key={id} w={{ base: '90%', lg: '350px' }}>
              <chakra.article width="100%">
                <ChakraLink as={Link} to={uri} aria-label="View the entire post">
                  <GatsbyImage image={postImage} alt={title} />
                </ChakraLink>
                <Box textAlign="center" mt={5}>
                  <chakra.h3>
                    <ChakraLink as={Link} to={uri} aria-label="View the entire post">
                      {title}
                    </ChakraLink>
                  </chakra.h3>
                  <Text
                    as="div"
                    dangerouslySetInnerHTML={{
                      __html: excerpt,
                    }}
                    noOfLines={4}
                    my={4}
                  />

                  <Link
                    className="font-bold text-center underline uppercase text-md font-heading text-darkBlue"
                    to={uri}
                    aria-label="View the entire post"
                  >
                    Learn more!
                  </Link>
                </Box>
              </chakra.article>
            </WrapItem>
          )
        })}
      </Wrap>
      <Center mt="40px">
        <Button as={Link} to="/blog" variant="lightBlue">
          See All Blog Posts
        </Button>
      </Center>
    </SectionBlock>
  )
}
