import { Box, Center, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import {
  Container,
  ContentButton,
  SectionBlock,
  TitleSubtitle,
} from 'starterUiComponents'

export const fragment = graphql`
  fragment ctaiconsBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_CtaiconsBlock {
    anchor
    cssclass
    subtitle
    textBelow
    title
    iconsText {
      icon {
        localFile {
          publicURL
        }
      }
      subtitle
      title
    }
    button {
      target
      title
      url
    }
  }
`
export const CtaiconsBlock = ({
  anchor,
  cssclass,
  subtitle,
  textBelow,
  title,
  iconsText,
  button,
  ...props
}) => {
  return (
    <SectionBlock anchor={anchor} cssclass={cssclass} {...props}>
      <Box
        borderRadius="20px"
        backgroundImage="url(/backpattern.jpg)"
        backgroundSize="cover"
        py={{ base: '30px', md: '60px' }}
        px={{ base: 2, sm: 0 }}
        color="white"
      >
        <Container sx={{ maxW: 960 }}>
          <TitleSubtitle
            title={title}
            subtitle={subtitle}
            color="white"
            mb={5}
            sx={{
              a: { color: 'yellow' },
              h2: { fontWeight: 'bold', fontSize: { base: 'xl', sm: 'xxl' } },
              strong: {
                display: 'block',
                fontFamily: 'heading',
                fontSize: 'l',
                mb: 4,
              },
            }}
          />

          <Stack
            direction={{ base: 'column', md: 'row' }}
            className="items-center justify-center md:items-start md:justify-between"
            spacing={5}
          >
            {iconsText?.map((item, i) => {
              const { title, subtitle, icon } = item

              return (
                <VStack
                  spacing={5}
                  className="items-center w-full text-center md:w-1/3"
                  maxW="260px"
                  key={i}
                >
                  <Center
                    width="100px"
                    height="100px"
                    className={`rounded-full items-center mb-5 ${
                      cssclass === 'ppc'
                        ? 'border-4 border-yellow'
                        : 'bg-lightBlue'
                    }`}
                  >
                    <img
                      src={icon?.localFile.publicURL}
                      height="60px"
                      width="60px"
                      alt={title}
                    />
                  </Center>
                  <Heading as="h4" fontSize="l" textTransform="uppercase" m={0}>
                    {title}
                  </Heading>
                  <Text
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                    fontSize="14px"
                    m={0}
                    sx={{ span: { color: 'yellow' } }}
                  />
                </VStack>
              )
            })}
          </Stack>
          <Center className="pb-10 pt-14">
            <ContentButton
              button={button}
              variant={cssclass === 'ppc' ? 'lightBlue' : 'outlineWhite'}
              sx={
                cssclass !== 'ppc' && {
                  borderColor: 'white',
                  color: 'yellow',
                  a: { width: '100%' },
                }
              }
            />
          </Center>
          {textBelow && (
            <Text
              fontWeight="bold"
              className="text-sm text-center sm:text-base"
              dangerouslySetInnerHTML={{ __html: textBelow }}
              sx={{ a: { color: 'yellow' } }}
            />
          )}
        </Container>
      </Box>
    </SectionBlock>
  )
}
