const rem = (number) => number / 16 + 'rem'
const Color = require('color')

const lighten = (clr, val) => Color(clr).lighten(val).rgb().string()
const darken = (clr, val) => Color(clr).darken(val).rgb().string()

const Button = {
  baseStyle: {
    width: { base: '100%', sm: '280px' },
    borderRadius: 10,
    textTransform: 'uppercase',
    fontFamily: 'heading',
    fontWeight: 'bold',
    fontSize: rem(18),
    color: 'white',
    // height: 55,
    textAlign: 'center',
    // py: 7,
    display: 'flex',
    alignItems: 'center',
    lineHeight: 0,
    cursor: 'pointer',
    _hover: { color: 'white' },
  },
  sizes: {
    lg: {
      height: '55px',
    },
    md: {
      height: '55px',
    },
    sm: {
      height: '55px',
    },
  },
  variants: {
    darkBlue: {
      bg: 'darkBlue',
      _hover: {
        bg: lighten('#466288', 0.5), // OLD:  #2C2D6B
      },
    },
    red: {
      bg: 'red',
      _hover: {
        bg: darken('#bd1e29', 0.2),
      },
    },
    lightBlue: {
      bg: 'lightBlue',
      _hover: {
        bg: darken('#50A1EC', 0.2),
      },
    },
    outline: {
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'yellow',
      color: 'white',
      _hover: {
        bg: 'yellow',
        color: 'text',
      },
    },
    outlineWhite: {
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'white',
      color: 'yellow',
      _hover: {
        bg: 'white',
        color: 'text',
      },
    },
    outlineDark: {
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'darkBlue',
      color: 'darkBlue',
      _hover: {
        bg: 'darkBlue',
        color: 'white',
      },
    },
    outlineLightBlue: {
      bg: 'transparent',
      border: '2px solid',
      borderColor: 'lightBlue',
      color: 'darkGrey',
      _hover: {
        bg: 'lightBlue',
        color: 'white',
      },
    },
  },
  defaultProps: {
    variant: 'darkBlue',
  },
}

export default Button
