import React from 'react'
import { Menu } from 'starterComponents'

export const FooterMenu = ({ menuName, ...props }) => (
  <Menu
    menuName={menuName}
    orientation="V"
    sx={{
      '.menu-item': {
        listStyleType: 'none',
        '@media only screen and (max-width: 800px)': { py: 3 },
      },
    }}
    {...props}
  />
)
