const p = {
  fontSize: ['base'],
  lineHeight: 'body',
  fontWeight: 500,
  mb: 5,
}
const a = {
  transition: 'all .2s',
  textDecoration: 'none',
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'none',
    color: 'lightBlue',
  },
}
const heading = {
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
  a: {
    borderBottom: 'none',
  },
}

const h1 = {
  ...heading,
  fontSize: ['3xl', '4xl'],
  // mt: 1,
}
const h2 = {
  ...heading,
  fontSize: ['l', 'xl'],
  // mt: 1,
}

const h3 = {
  ...heading,
  fontSize: ['md', 'l'],
  // mt: 2,
}
const h4 = {
  ...heading,
  fontSize: ['base', 'md'],
}

const h5 = {
  ...heading,
  fontSize: 'sm',
}
const h6 = {
  ...heading,
  fontSize: 'sm',
}

const base = {
  a,
  p,
  h1,
  h2,

  h3,
  h4,
  h5,
  h6,
}

const styles = {
  global: (props) => {
    return {
      body: {
        fontFamily: 'body',

        color: 'text',
        bg: 'white',
        // transition: 'all .4s ease-in-out',
        lineHeight: 'body',
      },
      '*::placeholder': {
        color: 'gray.400',
      },
      '*, *::before, &::after': {
        borderColor: 'gray.200',
        wordWrap: 'break-word',
      },
      '*:focus': {
        outlineStyle: 'dashed',
        outlineWidth: '0.5px',
      },

      blockquote: {
        fontStyle: 'italic',
        px: [5, 12],
        py: 8,
        borderLeft: '5px solid',
        borderColor: 'primary',
        maxWidth: '800px !important',
        mx: 'auto',
        my: 10,
        bg: 'light',
      },
      '::-webkit-search-cancel-button': {
        WebkitAppearance: 'none',
      },
      '*:focus:not(:focus-visible), [class]:focus:not(:focus-visible)': {
        outline: 'none',
        boxShadow: 'none',
      },
      '.listItems': {
        li: {
          listStyleType: 'none',
          position: 'relative',
          fontWeight: 600,
          pb: 2,
          '&:before': {
            content: "''",
            background: 'url(/check.svg) no-repeat',
            backgroundSize: '70%',
            height: 5,
            width: 5,
            position: 'absolute',
            left: -5,
            top: 1,
          },
        },
      },
      ...base,
    }
  },
}

export default styles
