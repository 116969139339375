import { Flex, Heading, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { SectionBlock, TitleSubtitle } from 'starterUiComponents'
import { sectionStyles } from './styles'

const GET_FEATURES = graphql`
  query {
    wp {
      options {
        featuresOptions {
          features {
            subtitle
            title
            link {
              url
            }
            icon {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export const fragment = graphql`
  fragment featuresBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_FeaturesBlock {
    title
    subtitle
    cssclass
    anchor
  }
`
export const FeaturesBlock = ({
  title,
  subtitle,
  cssclass,
  anchor,
  ...props
}) => {
  const data = useStaticQuery(GET_FEATURES)

  const { features } = data.wp.options.featuresOptions
  return (
    <SectionBlock
      anchor={anchor}
      cssclass={cssclass}
      bg="blue"
      sx={{ ...sectionStyles }}
      {...props}
    >
      <TitleSubtitle
        title={title}
        subtitle={subtitle}
        color="white"
        mb={10}
        sx={{ span: { color: 'yellow', fontWeight: 'bold' } }}
      />
      <Wrap spacing={{ base: 3, md: 20 }} justify="center">
        {features?.map((feature, i) => {
          const { title, subtitle, icon, link } = feature

          return (
            <WrapItem
              key={i}
              my="30px !important"
              className="px-4 text-white md:px-0 "
              w={200}
            >
              <VStack textAlign="center">
                <Flex
                  width="108px"
                  height="108px"
                  className="items-center justify-center border-4 rounded-full border-yellow mb-7"
                  sx={{ img: { height: '60px !important' } }}
                >
                  <Link to={link?.url}>
                    <img
                      src={icon?.localFile.publicURL}
                      alt={title}
                      height="60px"
                      width="60px"
                    />
                  </Link>
                </Flex>
                <Link
                  className="relative text-base uppercase font-heading"
                  to={link?.url}
                >
                  <Heading
                    as="h2"
                    fontSize="l"
                    maxW={200}
                    className="mt-0 mb-4 uppercase"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />

                  <Text
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                    fontSize="14px"
                    m={0}
                    sx={{ span: { color: 'yellow' } }}
                  />
                </Link>
              </VStack>
            </WrapItem>
          )
        })}
      </Wrap>
    </SectionBlock>
  )
}
