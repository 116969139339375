import React from 'react'
import { Flex } from '@chakra-ui/react'

import { Container } from 'starterUiComponents'
import { useThemeOptions } from '../../../context'
import { Sidebar } from 'starterComponents'
import { ArchiveTitle, PostsList, Pagination } from 'starterComponents'
import { useLayoutWidth } from 'starterUtils/hooks'

export const ArchiveContent = ({
  posts,
  name,
  nextPagePath,
  previousPagePath,
}) => {
  const { archiveSidebar, sidebarWidgets } = useThemeOptions()

  const [archiveWidth, xl] = useLayoutWidth('archive')

  const containerStyles =
    sidebarWidgets && archiveSidebar
      ? {
          maxWidth: xl,
          '.posts-list': {
            width: { base: '100%', lg: '67%', xl: '70%' },
          },
          '.sidebar': { width: { base: '100%', lg: '33%', xl: '30%' } },
        }
      : { maxWidth: archiveWidth() }

  const sidebarSide =
    sidebarWidgets && archiveSidebar
      ? archiveSidebar === `left`
        ? {
            flexDirection: `row-reverse`,
            '.posts-list': {
              pl: { base: 0, lg: 8 },
            },
          }
        : {
            '.posts-list': {
              pr: { base: 0, lg: 8 },
            },
          }
      : ''

  return (
    <Container className="mainContainer" sx={{ ...containerStyles }} mt={10}>
      {name && <ArchiveTitle text="Posts from: " name={name} />}
      <Flex
        sx={{
          ...sidebarSide,
          flexWrap: { base: `wrap`, lg: `wrap` },
          alignItems: `flex-start`,
        }}
      >
        <PostsList posts={posts} />
        {archiveSidebar && <Sidebar widgets={sidebarWidgets} />}
      </Flex>

      <Pagination
        nextPagePath={nextPagePath}
        previousPagePath={previousPagePath}
      />
    </Container>
  )
}
