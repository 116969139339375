import { Alert, Button, Center, Input } from '@chakra-ui/react'
import { useAuth } from 'contexts/AuthContext'
import { Link, navigate } from 'gatsby'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

export const Login = () => {
  const { handleSubmit, register } = useForm()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { login } = useAuth()

  const onSubmit = async (data, e) => {
    let { email, password } = data
    e.preventDefault()
    try {
      setError('')
      setLoading(true)
      if (password.length < 6) {
        password = password.padStart(6, '0')
      }
      await login(email, password)
      navigate('/dashboard')
    } catch (error) {
      const message = (error && error.message) || 'Log in attempt failed'
      setError(message)
    }

    setLoading(false)
  }
  return (
    <>
      {error && (
        <Alert color="white" status="error" className="mb-2">
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Input
            type="email"
            className="mx-auto mb-5"
            name="email"
            placeholder="email"
            ref={register}
          />

          <Input
            type="password"
            name="password"
            className="mb-8"
            placeholder="password"
            ref={register}
          />

          <Link
            to="/reset-password"
            className="font-bold uppercase text-darkBlue text-xs md:text-md font-heading block"
          >
            Forgot Password?
          </Link>
          <Link
            to="/recover-email"
            className="font-bold uppercase text-darkBlue text-xs md:text-md font-heading block"
          >
            Forgot Email?
          </Link>
        </div>
        <Center>
          <Button
            variant="lightBlue"
            disabled={loading}
            mt={4}
            type="submit"
            position={{ base: 'relative', md: 'absolute' }}
            bottom={{ base: '0', md: '14' }}
          >
            LogIn
          </Button>
        </Center>
      </form>
    </>
  )
}
