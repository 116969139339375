import { Box, Heading, Stack, VStack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { ContentButton, SectionBlock, TitleSubtitle } from 'starterUiComponents'

export const fragment = graphql`
  fragment columnsBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_ColumnsBlock {
    title
    subtitle
    cssclass
    anchor
    textBelow
    button {
      target
      title
      url
    }
    columns {
      content
      title
      subtitle
      button {
        target
        title
        url
      }
      link {
        target
        title
        url
      }
      image {
        altText
        localFile {
          publicURL
        }
      }
    }
  }
`
export const ColumnsBlock = ({
  title,
  subtitle,
  cssclass,
  anchor,
  columns,
  textBelow,
  button,
  ...props
}) => {
  const colsNumber = columns.length
  const spacing = colsNumber === 2 ? 12 : 5
  const cardPadding = colsNumber === 2 ? 'p-5 4 md:px-20' : 'p-3 md:px-8'
  const titleStyle =
    colsNumber === 2
      ? 'text-lightBlue text-l sm:text-l2'
      : 'text-darkGrey text-md sm:text-l'
  return (
    <SectionBlock
      anchor={anchor}
      backgroundImage="url(/backpattern.jpg)"
      backgroundSize="cover"
      py={{ base: '30px', md: '60px' }}
      cssclass={cssclass}
      {...props}
      sx={{
        '&.advantages': {
          mb: 16,
        },
      }}
    >
      <TitleSubtitle
        title={title}
        subtitle={subtitle}
        textAlign="center"
        sx={{
          h2: {
            color: 'white',
          },
          '.subtitle': {
            maxWidth: '1200px',
            color: 'white',
          },
        }}
        mb={10}
      />

      <Stack direction={{ base: 'column', lg: 'row' }} spacing={spacing}>
        {columns?.map((col, i) => {
          const { content, title, button, subtitle, link, image } = col

          return (
            <VStack
              key={i}
              className={`justify-between w-full  column lg:w-1/2 md:py-14 ${cardPadding} leading-tight bg-white rounded-twenty`}
            >
              <VStack spacing={5}>
                {image && (
                  <div className="flex justify-center mb-8">
                    <Box
                      h="108px"
                      w="108px"
                      className="flex items-center justify-center rounded-full bg-lightBlue"
                    >
                      <img
                        src={image?.localFile.publicURL}
                        width="65px"
                        height="100%"
                        alt={image.altText}
                      />
                    </Box>
                  </div>
                )}

                <h3
                  className={`text-center uppercase ${titleStyle}`}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <Heading
                  as="h4"
                  fontWeight="bold"
                  lineHeight={1.3}
                  fontSize="l"
                  textAlign="center"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />

                <Box
                  dangerouslySetInnerHTML={{ __html: content }}
                  sx={{
                    'p, li': { fontSize: 'sm', lineHeight: 1.6 },
                    ul: { pl: 6, mb: 4 },
                    '.techTitle': {
                      fontSize: 'sm',
                      fontWeight: 600,
                      mb: 4,
                      // fontFamily: 'heading',
                    },
                  }}
                />
              </VStack>
              <Box>
                {link && (
                  <ContentButton
                    button={link}
                    variant="unstyled"
                    sx={{
                      a: {
                        color: 'darkBlue',
                        textDecoration: 'underline',
                        _hover: { color: 'lightBlue', textDecoration: 'none' },
                      },
                    }}
                  />
                )}
                {button && (
                  <ContentButton
                    button={button}
                    variant={
                      cssclass?.includes('seminarColumns') ? 'red' : 'lightBlue'
                    }
                  />
                )}
              </Box>
            </VStack>
          )
        })}
      </Stack>
      {textBelow && (
        <div
          className="mt-12 font-bold text-center text-white"
          dangerouslySetInnerHTML={{ __html: textBelow }}
        />
      )}

      {button && (
        <ContentButton
          button={button}
          variant="red"
          justify="center"
          className="mt-10"
          sx={{ '.chakra-button': { width: 'auto', px: 7 } }}
        />
      )}
    </SectionBlock>
  )
}
