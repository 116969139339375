import {
  Button,
  Center,
  chakra,
  Flex,
  Icon,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { FaFilePdf, FaLink, FaVideo } from 'react-icons/fa'
import { SectionBlock } from 'starterUiComponents'

export const fragment = graphql`
  fragment resourcesBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_ResourcesBlock {
    cssclass
    anchor
    resources {
      type
      title
      link
      pdfFile {
        localFile {
          publicURL
        }
      }
    }
  }
`
export const ResourcesBlock = ({ cssclass, anchor, resources, ...props }) => {
  return (
    <SectionBlock anchor={anchor} cssclass={cssclass} {...props}>
      <Flex w="100%" justify="center">
        <Wrap spacing={5} justify={{ base: 'center', xl: 'flex-start' }}>
          {resources?.map((resource, i) => {
            const { type, title, pdfFile, link } = resource

            let buttonText
            let icon
            let buttonTarget
            if (type === 'pdf') {
              buttonText = 'Download PDF'
              icon = FaFilePdf
              buttonTarget = '_self'
            } else if (type === 'video') {
              buttonText = 'View'
              icon = FaVideo
              buttonTarget = link.startsWith('http') ? '_blank' : '_self'
            } else {
              buttonText = 'Visit Site'
              icon = FaLink
              buttonTarget = '_blank'
            }
            return (
              <WrapItem
                key={i}
                w={{ base: '350px', md: '300px', lg: '265px' }}
                h="378px"
                borderRadius="20px"
                bg="light"
                p={8}
              >
                <Flex
                  align="center"
                  justify="space-between"
                  h="100%"
                  w="100%"
                  direction="column"
                >
                  <VStack justify="center" spacing={4}>
                    <Center
                      w="100px"
                      h="100px"
                      borderRadius="full"
                      border="4px solid white"
                    >
                      <Icon w={12} h={12} as={icon} color="blue" />
                    </Center>

                    <chakra.h4
                      fontSize="md"
                      textAlign="center"
                      lineHeight={1.3}
                      dangerouslySetInnerHTML={{ __html: title }}
                      sx={{
                        span: { fontWeight: 'light', fontSize: 'sm', mt: 3 },
                      }}
                    />
                  </VStack>

                  <Flex justify="center" w="100%">
                    <Button
                      as="a"
                      variant="darkBlue"
                      width="220px"
                      href={
                        type === 'pdf' ? pdfFile?.localFile?.publicURL : link
                      }
                      download={type === 'pdf' ? true : false}
                      target={buttonTarget}
                    >
                      {buttonText}
                    </Button>
                  </Flex>
                </Flex>
              </WrapItem>
            )
          })}
        </Wrap>
      </Flex>
    </SectionBlock>
  )
}
