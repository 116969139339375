const defaultTheme = require('tailwindcss/defaultTheme')
// export * from './colors'
const colors = require('./colors')
const rem = (number) => number / 16 + 'rem'
module.exports = {
  colors,
  screens: {
    ...defaultTheme.screens,
  },
  spacing: {
    ...defaultTheme.spacing,
  },
  borderRadius: {
    ...defaultTheme.borderRadius,
    twenty: '20px',
  },

  borderWidth: {
    ...defaultTheme.borderWidth,
  },
  boxShadow: {
    ...defaultTheme.boxShadow,
  },

  //Typography
  letterSpacing: {
    ...defaultTheme.letterSpacing,
  },
  lineHeight: {
    ...defaultTheme.lineHeight,
  },
  fontWeight: {
    ...defaultTheme.fontWeight,
    heading: 'bold',
    body: 'regular',
  },
  fontFamily: {
    heading: 'Work Sans, sans-serif',
    body: 'Spartan, sans-serif',
  },
  fontSize: {
    // ...defaultTheme.fontSize,
    sm: [rem(14)],
    base: [rem(16)],
    md: [rem(18)],
    l: [rem(24)],
    l2: [rem(36)],
    xl: [rem(48)],
    xxl: [rem(60)],
  },
}
