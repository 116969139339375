import { Box, Heading, Stack, VStack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import React from 'react'
import { ManualSelect } from 'starterComponents'
import { ContentButton, SectionBlock } from 'starterUiComponents'

export const fragment = graphql`
  fragment findBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_FindBlock {
    title
    subtitle
    cssclass
    anchor
    content
    button {
      title
      url
      target
    }
    link {
      title
      url
      target
    }
  }
`

export const FindBlock = ({
  title,
  subtitle,
  cssclass,
  anchor,
  button,
  content,
  link,
  ...props
}) => {
  return (
    <SectionBlock
      anchor={anchor}
      cssclass={cssclass}
      bg="light"
      py={10}
      {...props}
    >
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={12}>
        <VStack
          layerStyle="card"
          spacing={4}
          className="justify-between w-full lg:w-1/2 "
        >
          {title && (
            <Heading
              dangerouslySetInnerHTML={{ __html: title }}
              className="text-center uppercase text-lightBlue"
              fontSize={{ base: 'l', sm: '36px' }}
              lineHeight={1.1}
            />
          )}
          {subtitle && (
            <Box
              dangerouslySetInnerHTML={{ __html: subtitle }}
              className="text-sm text-center"
            />
          )}
          {content && (
            <Box
              className="pl-6 md:pl-8 content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}

          {button && <ContentButton button={button} variant="lightBlue" />}
        </VStack>
        <Box className="w-full lg:w-1/2" layerStyle="card">
          <Heading className="mb-4 text-center uppercase">
            Find Your <span className="text-yellow">Manual </span>
          </Heading>
          <ManualSelect />
        </Box>
      </Stack>
    </SectionBlock>
  )
}
