import { Box, chakra, Stack } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { SectionBlock } from 'starterUiComponents'

export const fragment = graphql`
  fragment twoColsSimpleBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_TwoColsSimpleBlock {
    title
    content
    cssclass
    anchor
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            width: 250
            layout: FIXED
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`
export const TwoColsSimpleBlock = ({
  title,
  content,
  cssclass,
  anchor,
  image,
  ...props
}) => {
  return (
    <SectionBlock
      anchor={anchor}
      className={`md:mt-16 md:mb-24 my-16 ${cssclass}`}
      {...props}
    >
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={{ base: 10, md: '130px' }}
      >
        <Box minWidth={{ base: 'auto', md: 518 }}>
          <chakra.h2
            lineHeight={1.2}
            className="mb-5 text-center uppercase text-l2 md:text-xxl md:text-right"
            dangerouslySetInnerHTML={{ __html: title }}
            sx={{ span: { color: 'orange' } }}
          />
          <div className="flex justify-center">
            <GatsbyImage
              alt={image.altText}
              image={image?.localFile.childImageSharp.gatsbyImageData}
            />
          </div>
        </Box>

        <div
          className="pl-6 md:pl-0"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Stack>
    </SectionBlock>
  )
}
