import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import React from 'react'
import { Container } from './ui-components'

const nonBreadcrumbsPages = ["seminars", "webinars"];

const doNotShow = (props) => nonBreadcrumbsPages.includes(props?.data?.page?.slug)

// https://stackoverflow.com/questions/5796718/html-entity-decode
function convertHTMLEntity(text) {
  const span = document.createElement('span');

  return text
    .replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
      span.innerHTML = entity;
      return span.innerText;
    });
}

export const Breadcrumbs = (props) => {
  const { pageContext } = props
  const pageTitle = pageContext?.seo?.title.replace(' - ATSG', '')

  if (doNotShow(props)) return <></>

  return (
    <Container pt={4}>
      <Breadcrumb
        separator={<ChevronRightIcon color="text" />}
        fontSize="12px"
        spacing="3px"
        mb={4}
      >
        {pageContext?.seo?.breadcrumbs.map((crumbs, index) => {
          return (
            <BreadcrumbItem key={index}>
              <BreadcrumbLink
                as={Link}
                to={`${crumbs.url}`}
                fontWeight="normal"
              >
                {convertHTMLEntity(crumbs.text)}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )
        })}
      </Breadcrumb>
      <Heading
        fontSize={{ base: 'l', md: 'xl' }}
        className="mb-8 capitalize text-darkBlue"
      >
        {pageTitle !== 'FAQs' ? pageTitle : 'Frequently Asked Questions'}
      </Heading>
    </Container>
  )
}
