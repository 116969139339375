import React from 'react'
import { Helmet } from 'react-helmet'

import parse from 'html-react-parser'

const Seo = (props) => {
  const { title, lang = 'en', fullHead, schema } = props

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defer={false}
    >
      {fullHead && parse(fullHead)}

      {schema?.raw && (
        <script type="application/ld+json">{`
          ${schema.raw}
        `}</script>
      )}
    </Helmet>
  )
}

export default Seo
