import { Box, Button, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { SectionBlock, TitleSubtitle } from 'starterUiComponents'

export const fragment = graphql`
  fragment productsBlockFragment on WpPage_Flexlayouts_FlexibleLayouts_ProductsBlock {
    title
    cssclass
    anchor
    subtitle
    products {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
              layout: FIXED
            )
          }
        }
      }
      title
      price
      link
    }
  }
`
export const ProductsBlock = ({
  title,
  subtitle,
  cssclass,
  anchor,
  products,
  ...props
}) => {
  return (
    <SectionBlock
      anchor={anchor}
      cssclass={cssclass}
      {...props}
      pb="100px"
      mt="60px"
    >
      <TitleSubtitle title={title} subtitle={subtitle} />

      <Wrap justify="center" className="mt-10">
        {products?.map((product, i) => {
          const { image, title, price, link } = product
          const productImage = getImage(image.localFile)
          return (
            <WrapItem
              pb="15px "
              pl="30px"
              pr="30px"
              key={i}
              className="text-center"
            >
              <VStack>
                <GatsbyImage image={productImage} alt="product image" />
                <Box maxW="200px" mt={3}>
                  {title}
                </Box>
                <div className="my-3 font-bold text-l">${price}.00</div>
                <Button
                  as="a"
                  href={link}
                  variant="darkBlue"
                  target="_blank"
                  rel="noopener"
                  width="224px"
                >
                  View Product
                </Button>
              </VStack>
            </WrapItem>
          )
        })}
      </Wrap>
    </SectionBlock>
  )
}
