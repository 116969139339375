import { Box, Heading, WrapItem } from '@chakra-ui/react'
import React from 'react'

export const FooterColumn = ({ title, children, ...props }) => {
  return (
    <WrapItem {...props}>
      <Box maxW={300}>
        <Heading className="uppercase " fontSize="lg" lineHeight={1.7}>
          {title}
        </Heading>
        <Box
          fontSize="sm"
          sx={{
            a: { fontWeight: 'normal', '&:hover': { color: 'lightBlue' } },
          }}
        >
          {children}
        </Box>
      </Box>
    </WrapItem>
  )
}
