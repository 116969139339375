import Input from './Input'

const baseStyle = {
  ...Input.baseStyle.field,
  p: 5,
  minHeight: '80px',
  lineHeight: 'short',
}

const variants = {
  outline: (props) => Input.variants.outline(props).field,
}

const sizes = {
  md: Input.sizes.md.field,
}

const defaultProps = {
  size: 'md',
  variant: 'outline',
}

const Textarea = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
}

export default Textarea
