import { Box, HStack, Link as ChakraLink, Text } from '@chakra-ui/react'
import { Link } from 'gatsby'
import React from 'react'
import { SocialFollows } from 'starterComponents'
import { Container } from 'starterUiComponents'

export const FooterContent = (props) => {
  return (
    <Box {...props}>
      <Container className="items-center justify-between block lg:flex">
        <Box className="mb-5 text-center lg:mb-0 lg:text-left">
          <Text mb={0} fontSize="sm">
            © {new Date().getFullYear()} Automatic Transmission Service Group
            www.atsg.com
          </Text>
          <Text mb={0} fontSize="sm">
            All rights reserved.
            <ChakraLink as={Link} fontWeight="light" to="/privacy-policy">
              {' '}
              Privacy Policy
            </ChakraLink>{' '}
            |{' '}
            <ChakraLink as={Link} fontWeight="light" to="/terms-conditions">
              {' '}
              Terms & Conditions
            </ChakraLink>
          </Text>
        </Box>
        <HStack spacing={10} className="justify-center lg:justify-start">
          <SocialFollows />
          {/* <Button
            variant="lightBlue"
            borderRadius="30px"
            px={7}
            width="auto"
            leftIcon={<Icon as={FaComments} />}
          >
            Live Chat
          </Button> */}
        </HStack>
      </Container>
    </Box>
  )
}
