import firebase from 'firebase/app'
import 'firebase/auth' // importing the auth module as an example
import 'firebase/storage'

let instance = null

export default function getFirebase(config) {
  if (typeof window !== 'undefined') {
    if (instance) return instance
    instance = firebase.initializeApp(config)
    return instance
  }

  return null
}
