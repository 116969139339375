import React from 'react'

export const DashbordElements = ({ title, value, className, ...props }) => {
  return (
    <div className={`flex justify-between ${className} mb-1.5`} {...props}>
      <div className="font-semibold text-[14px]">{title}:</div>
      <div className="md:w-[200px]"> {value}</div>
    </div>
  )
}
