import React from 'react'
import { useMenusQuery } from '../../hooks/useMenusQuery'

import { chakra } from '@chakra-ui/react'
import { MenuItem, SubMenu, flatListToHierarchical } from 'starterComponents'

export const Menu = ({ menuName, orientation, onClose, ...props }) => {
  const menuEdges = useMenusQuery()
  const menuEdge = menuEdges.find((n) => menuName === n.name)
  const menuItems = menuEdge ? menuEdge.menuItems : null

  const styleVariant = orientation === 'V' ? menuVStyles : menuHStyles
  const style = {
    ...styleVariant,
    '[aria-current]': {
      color: 'yellow',
      fontWeight: 'body',
    },
    '&.menuLeft, &.menuRight': {
      'li:hover, a:hover': { color: 'yellow' },
    },
    '&.menuTop': {
      'li:hover, a:hover': { color: 'lightBlue' },
    },

    li: {
      listStyle: 'none',

      fontFamily: 'heading',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: 'base',
      px: { base: 3, xl: 5 },
    },
    '.sub-menu a': {
      fontWeight: 'normal',
      color: 'white',
      '&:hover': { color: 'orange' },
    },
  }

  if (menuItems) {
    const menuNodes = flatListToHierarchical(menuItems.nodes, { idKey: 'id' })
    return (
      <chakra.nav
        className="menu"
        sx={{ ...style }}
        aria-label="main"
        {...props}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */}
        <ul className="menuItemGroup">
          {menuNodes.map((menuItem, i) => {
            if (menuItem.children.length) {
              return (
                <SubMenu
                  role="menu"
                  menuItem={menuItem}
                  orientation={orientation}
                  key={i}
                  onClose={onClose}
                />
              )
            } else {
              return (
                <MenuItem
                  role="menu"
                  menuItem={menuItem}
                  key={i}
                  onClose={onClose}
                />
              )
            }
          })}
        </ul>
      </chakra.nav>
    )
  } else {
    return null
  }
}

export const menuHStyles = {
  '&.menuLeft': {
    'li:hover': {
      bg: 'blueSubMenu',
    },
  },
  '>ul': {
    display: 'flex',
    '>li': {
      py: '18px',
      cursor: 'pointer',

      '&.has-subMenu': {
        py: '18px',
        cursor: 'pointer',
        position: 'relative',
        // bg: 'darkBlue',
        '&:hover': {
          '.sub-menu': {
            display: 'block',
          },
        },
      },
    },
  },
}

export const menuVStyles = {
  '.menu-item': {
    py: 3,
    cursor: 'pointer',
    '&:hover,&:focus, a:focus, a:hover': { color: 'yellow' },
  },
}
