import {Flex, Button} from '@chakra-ui/react'
import React from 'react'
import {Link} from 'gatsby'
import config from 'config'
// import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const ContentButton = ({button, variant, ...props}) => {
    const {title, target, url} = button || {}
    const buttonUrl = button && url.replace(config.wordPressUrl, '')

    return (
        <Flex {...props}>
            {target === '_blank' ? (
                    <Button
                        as="a"
                        href={url}
                        variant={variant}
                        target="_blank"
                        rel="noopener"
                    >
                        {title}
                    </Button>
                ) :
                target === '_parent' ? (
                        <Button
                            as="a"
                            href={url}
                            variant={variant}
                            target="_parent"
                            rel="noopener"
                        >
                            {title}
                        </Button>
                    ) :
                    url?.includes('tel:') ? (
                        <Button as="a" href={url} variant={variant} rel="noopener">
                            {title}
                        </Button>
                    ) : (
                        <Button as={Link} to={buttonUrl} variant={variant}>
                            {title}
                        </Button>
                    )}
        </Flex>
    )
}

export default ContentButton
